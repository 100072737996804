

export const canvasImgCode = {
  /**生成一个随机数**/
  randomNum (min,max) {
    return Math.floor( Math.random()*(max-min)+min);
  },
  /**生成一个随机色**/
  randomColor (min, max){
    let r = this.randomNum(min,max)
    let g = this.randomNum(min,max)
    let b = this.randomNum(min,max)
    return "rgb("+r+","+g+","+b+")"
  },
  /**绘制验证码图片**/
  drawPic () {
    let canvas=document.querySelector('#canvas')
    let width=canvas.width
    let height=canvas.height
    let ctx = canvas.getContext('2d')
    let txt
    let txtCode = ''
    ctx.textBaseline = 'top'
    /**绘制背景色**/
    ctx.fillStyle = '#ccc' // this.randomColor(180,240) //颜色若太深可能导致看不清
    ctx.fillRect(0,0,width,height)
    /**绘制文字**/
    let str = 'ABCEFGHJKLMNPQRSTWXY123456789'
    for(let i=0; i<4; i++){
      txt = str[this.randomNum(0,str.length)]
      txtCode += txt
      /*ctx.fillStyle =  this.randomColor(50,160)  //随机生成字体颜色
      ctx.font = '80px Arial'
      let x = 10+i*50
      let y = this.randomNum(25,45)
      let deg = this.randomNum(-45, 45)
      //修改坐标原点和旋转角度
      ctx.translate(x,20)
      ctx.rotate(deg*Math.PI/180)
      ctx.fillText(txt, 0,0)
      //恢复坐标原点和旋转角度
      ctx.rotate(-deg*Math.PI/180)
      ctx.translate(-x,20)*/
    }
    ctx.fillStyle = '#ffffff'
    ctx.font = '70px Arial'
    ctx.fillText(txtCode, 10,height/5)
    /**绘制干扰线**/
    /*for(let i=0; i<4; i++){
      ctx.strokeStyle = this.randomColor(40,180)
      ctx.beginPath()
      ctx.moveTo( this.randomNum(0,width), this.randomNum(0,height) )
      ctx.lineTo( this.randomNum(0,width), this.randomNum(0,height) )
      ctx.stroke()
    }*/
    /**绘制干扰点**/
    /*for(let i=0; i<20; i++){
      ctx.fillStyle = this.randomColor(0,255)
      ctx.beginPath()
      ctx.arc(this.randomNum(0,width),this.randomNum(0,height), 1, 0, 2*Math.PI)
      ctx.fill()
    }*/
    return txtCode
  }
}
