export const validate = {
  checkMobile (mobile) {
    // 验证手机
    return new Promise((resolve, reject) => {
      var reg = /^1[3|4|5|7|8]\d{9}$/
      if (!(reg.test(mobile))) {
        reject('请输入正确的手机号码')
      } else {
        resolve(mobile)
      }
    })
  }
}