export const cookie = {
  GetCookie (name) {
    // 取Cookie的值
    var arg = name + '='
    var alen = arg.length
    var clen = document.cookie.length
    var i = 0
    while (i < clen) {
      var j = i + alen
      if (document.cookie.substring(i, j) === arg) return this.getCookieVal(j)
      i = document.cookie.indexOf(' ', i) + 1
      if (i === 0) break
    }
    return null
  },
  SetCookie (name, value, time) {
    // 写入到Cookie
    // name:cookie名称  value:cookie值
    this.DelCookie(name)
    var exp = new Date()
    exp.setTime(time)
    document.cookie = name + '=' + encodeURI(value) + ';expires=' + exp.toGMTString()
  },
  DelCookie (name) {
    var exp = new Date()
    exp.setTime(exp.getTime() - 1)
    var cval = this.GetCookie(name)
    if (cval !== null) {
      document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
    }
  },
  getCookieVal (offset) {
    var endstr = document.cookie.indexOf(';', offset)
    if (endstr === -1) endstr = document.cookie.length
    return decodeURI(document.cookie.substring(offset, endstr))
  }
}